/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Configuration

@import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
//  @import "images";
@import "bootstrap/scss/grid";
//  @import "tables";
@import "bootstrap/scss/forms";
//  @import "buttons";
//  @import "transitions";
//  @import "dropdown";
//  @import "button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//  @import "card";
//  @import "breadcrumb";
//  @import "pagination";
//  @import "badge";
//  @import "alert";
//  @import "progress";
//  @import "list-group";
//  @import "close";
//  @import "toasts";
//  @import "modal";
//  @import "tooltip";
//  @import "popover";
//  @import "carousel";
//  @import "spinners";


// Utilities

// @import "bootstrap/scss/utilities/api";
