@import "custom-variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap-selected-imports";
@import "syntax-highlighting";


.bg-primary {
  background-color: #e3583e !important;
}

html {
  overflow-y: scroll;
}

h1,
h2,
h3,
h4 {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
}

.blog-masthead {
  -webkit-box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.1);
}

/*
* Blog name and description
*/

.blog-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.blog-title {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 60px;
}

.blog-description {
  font-size: 20px;
  color: #999;
}

/*
* Blog posts
*/

.blog-post-list-item {
  margin-bottom: 30px;
}

.blog-post {
  margin-bottom: 60px;
}

.blog-post-title {
  margin-bottom: 10px;
  margin-top: 5px;
}

.blog-post-meta {
  margin-bottom: 15px;
  color: #999;
  font-size: ceil($font-size-sm * 0.9);
  & .home {
    margin-bottom: 0px;
  }
}

/*
* Footer
*/

.blog-footer {
  padding: 20px 0;
  margin-top: 20px;
  color: darken($primary, 50);
  background-color: lighten($primary, 45);
  border-top: 1px solid lighten($primary, 30);
}

/*
* Code snippets
*/

pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
}
